<template>
  <div class="all-page">
    <TopNav title="订单详情" backPath="GameOrderIndex"></TopNav>
    <div class="all-page-content-box">
      <!-- <div class="title">
               奇遇游戏
           </div> -->
      <div class="order-num">-{{ orderInfo.final_pay_money | changMoney }}</div>
      <div class="order-info-box">
        <div class="order-info">
          <div class="order-info-item">
            <div class="order-info-item-title">游戏名称</div>
            <div class="order-info-item-value">
              {{ orderInfo.game_name }}
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">商品</div>
            <div class="order-info-item-value">
              {{ orderInfo.goods_name }}
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">订单金额</div>
            <div class="order-info-item-value">
              ￥{{ orderInfo.order_money | changMoney }}
            </div>
          </div>
          <div
            class="order-info-item"
            v-if="orderInfo.discount_ratio && orderInfo.discount_ratio > 0"
          >
            <div class="order-info-item-title">
              折扣抵扣（{{ orderInfo.discount_ratio / 1000 }}）
            </div>
            <div class="order-info-item-value">
              -￥{{ orderInfo.discount_money | changMoney }}
            </div>
          </div>
          <div class="order-info-item" v-if="orderInfo.is_use_coin === 1">
            <div class="order-info-item-title">平台币</div>
            <div class="order-info-item-value">
              -{{ orderInfo.pay_coin | changMoney }}
            </div>
          </div>
          <div class="order-info-item" v-if="orderInfo.is_use_game_coin === 1">
            <div class="order-info-item-title">游戏币</div>
            <div class="order-info-item-value">
              -{{ orderInfo.pay_game_coin | changMoney }}
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">实际支付金额</div>
            <div class="order-info-item-value">
              ￥{{ orderInfo.real_money | changMoney }}
            </div>
          </div>
        </div>
        <div class="order-pay-info">
          <div class="order-info-item">
            <div class="order-info-item-title">支付方式</div>
            <div class="order-info-item-value">
              <span
                v-if="
                  orderInfo.is_use_coin === 1 &&
                  orderInfo.pay_way_name !== '平台币'
                "
                >平台币+</span
              ><span
                v-if="
                  orderInfo.is_use_game_coin === 1 &&
                  orderInfo.pay_way_name !== '游戏币'
                "
                >游戏币+</span
              ><span>{{ orderInfo.pay_way_name }}</span>
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">创单时间</div>
            <div class="order-info-item-value">
              {{ orderInfo.create_time | changTimeType }}
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">支付时间</div>
            <div class="order-info-item-value">
              {{ orderInfo.pay_time | changTimeType }}
            </div>
          </div>
          <div class="order-info-item">
            <div class="order-info-item-title">平台订单号</div>
            <div class="order-info-item-value">
              {{ orderInfo.order_number }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from "@/components/Layout/TopNav.vue";

export default {
  components: {
    TopNav,
  },
  props: {
    orderInfo: Object,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* .title {
       font-size: 15px;
       font-weight: 400;
       line-height: 14px;
       color: #666666;
       text-align: center;
   } */
.order-num {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #000000;
  text-align: center;
}
.order-info {
  padding-top: 16.25px;
  border-bottom: 1px solid #e4e4e4;
}
.order-info-item {
  padding-bottom: 6.25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.order-info-item-title {
  font-size: 8.75px;
  font-weight: 400;
  color: #666666;
}
.order-info-item-value {
  font-size: 8.75px;
  font-weight: 400;
  color: #000000;
  flex: 1;
  text-align: end;
  padding-left: 18px;
}
.order-pay-info {
  padding-top: 10px;
}
</style>
